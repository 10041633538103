import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "../src/assets/style/reset.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    // 兼容chrome
    document.body.scrollTop = 0
        // 兼容firefox
    document.documentElement.scrollTop = 0
        // 兼容safari
    window.pageYOffset = 0
    next()
})

Vue.use(ElementUI);
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')