import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
    // 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/About.vue'),
        children: [{
                path: '/about/equity',
                name: 'Equity',
                component: () =>
                    import ('../views/Child_web/Equity.vue')
            },
            {
                path: '/about/distribution',
                name: 'Distribution',
                component: () =>
                    import ('../views/Child_web/Distribution.vue')
            },
            {
                path: '/about/shopping',
                name: 'Shopping',
                component: () =>
                    import ('../views/Child_web/Shopping.vue')
            },
            {
                path: '/about/analysis',
                name: 'Analysis',
                component: () =>
                    import ('../views/Child_web/Analysis.vue')
            },
            {
                path: '/about/platform',
                name: 'Platform',
                component: () =>
                    import ('../views/Child_web/Platform.vue')
            },
            {
                path: '/about/digitization',
                name: 'Digitization',
                component: () =>
                    import ('../views/Child_web/Digitization.vue')
            },
            {
                path: '/about/hotelscheme',
                name: 'Hotelscheme',
                component: () =>
                    import ('../views/Child_web/Hotelscheme.vue')
            },
            {
                path: '/about/apartmentscheme',
                name: 'Apartmentscheme',
                component: () =>
                    import ('../views/Child_web/Apartmentscheme.vue')
            },
            {
                path: '/about/cateringprogram',
                name: 'Cateringprogram',
                component: () =>
                    import ('../views/Child_web/Cateringprogram.vue')
            },
            {
                path: '/about/product',
                name: 'Product',
                component: () =>
                    import ('../views/Child_web/Product.vue')
            },
            {
                path: '/about/news',
                name: 'News',
                component: () =>
                    import ('../views/Child_web/News.vue')
            },
            {
                path: '/about/consulting',
                name: 'Consulting',
                component: () =>
                    import ('../views/Child_web/Consulting.vue')
            },
            {
                path: '/about/flow',
                name: 'Flow',
                component: () =>
                    import ('../views/Child_web/Flow.vue')
            },
            {
                path: '/about/SCRM',
                name: 'SCRM',
                component: () =>
                    import ('../views/Child_web/SCRM.vue')
            },
            {
                path: '/about/activity',
                name: 'Activity',
                component: () =>
                    import ('../views/Child_web/Activity.vue')
            },
            {
                path: '/about/member',
                name: 'Member',
                component: () =>
                    import ('../views/Child_web/Member.vue')
            },
            {
                path: '/about/enterprise',
                name: 'Enterprise',
                component: () =>
                    import ('../views/Child_web/Enterprise.vue')
            },
            {
                path: '/about/label',
                name: 'Label',
                component: () =>
                    import ('../views/Child_web/Label.vue')
            },
            {
                path: '/about/aboutus',
                name: 'Aboutus',
                component: () =>
                    import ('../views/Child_web/Aboutus.vue')
            },
            {
                path: '/about/joinus',
                name: 'Joinus',
                component: () =>
                    import ('../views/Child_web/Joinus.vue')
            },
            {
                path: '/about/cooperation',
                name: 'Cooperation',
                component: () =>
                    import ('../views/Child_web/Cooperation.vue')
            },
        ]
    }
]

const router = new VueRouter({
    routes
})

export default router